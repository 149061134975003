.group-company {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
}

.group-company__title {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    color: var(--basic-black);
}

.group-company__slider {
    display: flex;
    flex-wrap: nowrap;
}

.group-company .swiper-container {
    overflow: clip;
}

.group-company__slider-item {
    position: relative;
    box-sizing: border-box;
    min-height: 190px;
    height: 190px;
    padding: 48px;
    background: #ffffff;
    border-radius: 40px;
    align-items: center;
}

.group-company__slider-item-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-company__slider-item-link--img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

@media (max-width: 601px) {
    .group-company {
        margin-bottom: 64px;
    }

    .group-company__title {
        font-size: 42px;
    }
}
