.group-company {
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  display: flex;
}

.group-company__title {
  color: var(--basic-black);
  margin: 0;
  font-size: 52px;
  font-weight: 700;
}

.group-company__slider {
  flex-wrap: nowrap;
  display: flex;
}

.group-company .swiper-container {
  overflow: clip;
}

.group-company__slider-item {
  box-sizing: border-box;
  background: #fff;
  border-radius: 40px;
  align-items: center;
  height: 190px;
  min-height: 190px;
  padding: 48px;
  position: relative;
}

.group-company__slider-item-link {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.group-company__slider-item-link--img {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 601px) {
  .group-company {
    margin-bottom: 64px;
  }

  .group-company__title {
    font-size: 42px;
  }
}
/*# sourceMappingURL=index.9b64cb49.css.map */
